import {catchError, map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';


import {Observable, Subject} from 'rxjs';
import {AppStateService} from '@core/state/app-state.service';
import {Hour} from '../../model/reservation/hour';
import {PriceResult} from '../../model/reservation/price-result';
import {ReservationDetails} from '../../model/reservation/reservation-details';
import {ReservationState} from '../../model/reservation/reservation-state';
import {API_HOST} from '../../consts';
import {PaymentPrzelewy24} from '@model/reservation/paymentPrzelewy24';
import {PaymentTpay} from '@model/tournament/tournament-details';

@Injectable()
export class MakeReservationRepository {
    constructor(private http: HttpClient,
                private appState: AppStateService) {

    }

    public makeReservation(reservationDetails: ReservationDetails): Observable<ReservationState> {
        return this.http.post<ReservationState>(
            `${API_HOST}/reservation`,
            this.mapDetailsToRequestParams(reservationDetails)).pipe(
            map((response: any) => {
                    this.appState.reservationUpdate();
                    return new ReservationState(
                        false,
                        response.code,
                        reservationDetails,
                        response.public_uuid,
                        response.payment,
                        this.resolvePaymentsTpay(response),
                        this.resolvePaymentsPrzelewy24(response)
                    );
                }
            ), catchError(
                (error: any) => {
                    let errorCode = error.error;
                    let message = '';

                    const subject: Subject<any> = new Subject;
                    switch (errorCode.error) {
                        case 'HOUR_OCCUPIED':
                            message = 'Nieprawidłowa godzina';
                            break;
                        case 'UNCONFIRMED':
                            message = 'Nie udało się potwierdzić rezerwacji';
                            break;
                        case 'DELETED':
                            message = 'Rezerwacja skasowana';
                            break;
                        case 'DATE_ALREADY_PASSED':
                            message = 'Nie można dodać rezerwacji w przeszłości';
                            break;
                        case 'INCORRECT_PAYMENT_METHOD':
                            message = 'Wybrany cennik jest dostępny jedynie przy płatności online';
                            break;
                        case 'ACTIVE_RESERVATION_LIMIT_EXCEED':
                            message = 'Na tym korcie nie możesz posiadać więcej aktywnych rezerwacji';
                            break;
                        case 'RESERVATION_LIMIT_EXCEED':
                            message = 'Zgodnie z regulaminem kortu jedna osoba może zarezerwować jedną godzinę dziennie';
                            break;
                        default:
                            message = errorCode.message;
                    }

                    subject.error(new ReservationState(
                        true,
                        message,
                        reservationDetails,
                        null,
                        null
                    ));
                    return subject.asObservable();
                }
            ));
    }

    public resolvePaymentsPrzelewy24(response: any): PaymentPrzelewy24 | null {
        if (response.payments && Array.isArray(response.payments) && response.payments.length > 0) {
            const dto = response.payments.find(p => p.type === 'PRZELEWY24');
            if (dto) {
                return new PaymentPrzelewy24(
                    dto.price,
                    dto.przelewy24_sdk,
                    dto.przelewy24_token,
                    dto.type
                );
            }
        }

        return null;
    }

    private resolvePaymentsTpay(response: any): PaymentTpay | null {
        if (response.tpay_payment) {
            const dto = response.tpay_payment;
            return new PaymentTpay(
                dto.amount,
                dto.client_email,
                dto.client_name,
                dto.crc,
                dto.description,
                dto.id,
                dto.md5_code,
                dto.result_url
            );
        }

        return null;
    }

    public calculatePrice(reservationDetails: ReservationDetails, tournamentId?: number): Observable<PriceResult[]> {
        const start = this.mapDateAndHourToString(reservationDetails.day, reservationDetails.begin);
        const end = this.mapDateAndHourToString(reservationDetails.day, reservationDetails.end);
        const tournament = tournamentId ? `&tournamentId=${tournamentId}` : '';
        let params = new HttpParams();
        if (tournamentId) {
            params = params.append('tournamentId', tournamentId);
        }
        params = params.append('lightning', reservationDetails.lighting);
        params = params.append('start', start);
        params = params.append('end', end);
        params = params.append('multisport', reservationDetails.multisport);
        params = params.append('medicover', reservationDetails.medicover || 0);

        // return this.http.get<PriceResult[]>(`${API_HOST}/price/tariff/calculate/${reservationDetails.stationId}?lightning=${reservationDetails.lighting}&start=${start}&end=${end}&multisport=${reservationDetails.multisport}${tournament}`);
        return this.http.get<PriceResult[]>(`${API_HOST}/price/tariff/calculate/${reservationDetails.stationId}`, {params});
    }

    private mapDetailsToRequestParams(reservationDetails: ReservationDetails): Object {
        const params: any = {};
        params.group_reservation_id = reservationDetails.groupReservationId;
        params.starts_at = this.mapDateAndHourToString(reservationDetails.day, reservationDetails.begin);
        params.ends_at = this.mapDateAndHourToString(reservationDetails.day, reservationDetails.end);
        params.station_id = reservationDetails.stationId;
        params.payment_method = reservationDetails.payment_method;
        params.tariff_id = reservationDetails.tariff_id;
        params.multisport_cards = reservationDetails.multisport;
        params.medicover_cards = reservationDetails.medicover;
        params.lightning = reservationDetails.lighting;
        params.message = reservationDetails.message;
        // params.przelewy24_return_url = window.location.origin + `/#/?p24success=true&paymentId=${reservationDetails.paymentId}`;
        params.przelewy24_return_url = window.location.href + `?p24success=true&paymentId=${reservationDetails.paymentId}`;
console.log('przelewy24_return_url', params.przelewy24_return_url);

        return params;
    }

    private mapDateAndHourToString(date: Date, hour: Hour): string {
        const newDate = new Date(date);
        newDate.setHours(hour.hour);
        newDate.setMinutes(hour.minute);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);
        return this.adjustForTimezone(newDate).toISOString();
    }

    private adjustForTimezone(date: Date): Date {
        const timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
        date.setTime(date.getTime() - timeOffsetInMS);
        return date;
    }
}
